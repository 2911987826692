<template>
  <div
    class="
      text-center
      d-flex
      align-items-center
      justify-content-between
      full-screen
    "
  >
    <div class="text-center mx-auto">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        type="grow"
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BSpinner,
  },
  data() {
    return {
      loggingIn: true,
    };
  },
  methods: {
    validateAuthCode() {
      if (!this.$route.query.code || !this.$route.query.state) {
        return this.$router.push("/login");
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google`,
          {
            code: this.$route.query.code,
            clientID: atob(this.$route.query.state),
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const clientSlug = response.data.slug;
          this.$router.push(
            `/client/${clientSlug}?next=google-analytics-account-selection`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.$router.push("/client/list?error=google-login-error");
        });
    },
  },
  mounted() {
    this.validateAuthCode();
  },
};
</script>

<style scoped>
.full-screen {
  height: 100vh;
}
</style>